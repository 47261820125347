const getApiUrl = () => {
	if (process.env.REACT_APP_ENV === 'development') {
		return 'http://localhost:8080/';
	}
	if (process.env.REACT_APP_ENV === 'production') {
		return 'https://bumbei-apis-production.up.railway.app/';
	}
	return 'http://localhost:8080/';
};

const API_URL = getApiUrl();

export default API_URL;
