// Chakra imports
import {
	Alert,
	AlertDescription,
	AlertIcon,
	Button,
	Flex,
	FormControl,
	SimpleGrid,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { updateProfile } from 'api/user';
import Card from 'components/card/Card.js';
import InputField from 'components/fields/InputField';
import { ALERT, ERROR, SUCCESS } from 'constant/messages';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from 'store/user';

export default function Information(props) {
	const { ...rest } = props;
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);

	const textColorPrimary = useColorModeValue('gray.900', 'gray.100');
	const textColorSecondary = useColorModeValue('gray.900', 'gray.100');
	const brandColor = useColorModeValue('brand.500', 'gray.100');
	const inputBorder = useColorModeValue('gray.900', 'gray.100');
	const cardBg = useColorModeValue('gray.100', 'gray.500');

	const userDataState = useSelector((state) => state.user);
	const { userLightningAddress } = useSelector((state) => state.user);

	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(false);

	const [profile, setProfile] = useState({
		lightningAddress: userLightningAddress,
	});

	const [message, setMessage] = useState({
		state: false,
		status: '',
		description: '',
	});

	const validateLightningAddress = async (lightningAddress) => {
		// First layer of validation: format check
		const lightningAddressPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!lightningAddressPattern.test(lightningAddress)) {
			return false;
		}

		// Second layer of validation: fetch .well-known/lnurlp endpoint
		const [username, domain] = lightningAddress.split('@');
		const lnurlpUrl = `https://${domain}/.well-known/lnurlp/${username}`;

		try {
			const response = await fetch(lnurlpUrl);
			if (response.ok) {
				const data = await response.json();
				return data && data.callback;
			}
		} catch (error) {
			console.error('Error validating lightning address:', error);
		}

		return false;
	};

	const handleUpdateProfile = async () => {
		setIsLoading(true);
		let isValidLightningAddress = await validateLightningAddress(
			profile.lightningAddress
		);
		if (!isValidLightningAddress) {
			setProfile((prevState) => ({
				...prevState,
				lightningAddress: userLightningAddress,
			}));
			handleMessage('error', ERROR.VALID_LNADDRESS);
			setIsLoading(false);
			return;
		}

		try {
			const response = await updateProfile(TOKEN, USERID, profile);
			const result = await response.json();
			if (response.status === 200 && result.status === 'SUCCESS') {
				handleMessage('success', SUCCESS.PROFILE_UPDATED);
				dispatch(
					userActions.setUserData({
						...userDataState,
						userLightningAddress: profile.lightningAddress,
					})
				);
			} else {
				handleMessage('error', ALERT.ERROR_TITLE + '. ' + ALERT.ERROR_SUBTITLE);
			}
		} catch (error) {
			handleMessage('error', ALERT.ERROR_TITLE + '. ' + ALERT.ERROR_SUBTITLE);
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleMessage = (status, description) => {
		setMessage({
			state: true,
			status: status,
			description: description,
		});
	};

	const handleFocus = () => {
		setMessage({
			state: false,
			status: '',
			description: '',
		});
	};

	return (
		<Card bg={cardBg} mb='20px' {...rest}>
			<FormControl>
				<Flex direction='column' mb='30px' ms='10px'>
					<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
						⚡Red Lightning
					</Text>
					<Text fontSize='md' color={textColorSecondary}>
						¿Cuentas ya con una dirección lightning? Ingresa tu LNAddress.
					</Text>
					{!isLoading && message.state && (
						<Alert mt='15px' status={message.status || 'error'}>
							<AlertIcon />
							<AlertDescription>{message.description}</AlertDescription>
						</Alert>
					)}
				</Flex>

				<SimpleGrid
					columns={{ sm: 1, md: 2 }}
					spacing={{ base: '20px', xl: '20px' }}
				>
					<InputField
						_focus={{ borderWidth: 2, borderColor: brandColor }}
						_placeholder={{ color: textColorPrimary }}
						borderColor={inputBorder}
						bg={cardBg}
						mb='0px'
						me='30px'
						id='user'
						label='Dirección lightning'
						placeholder={
							userLightningAddress || 'Ingresa aquí tu dirección lightning...'
						}
						value={profile.lightningAddress}
						onFocus={handleFocus}
						onChange={(e) => {
							setProfile((prevState) => ({
								...prevState,
								lightningAddress: e.target.value,
							}));
						}}
					/>
				</SimpleGrid>
			</FormControl>
			<Button
				isLoading={isLoading}
				isDisabled={profile.lightningAddress === userLightningAddress}
				borderRadius='16px'
				minW='183px'
				h='44px'
				ms='auto'
				mt='33px'
				variant={'brand'}
				fontSize='sm'
				fontWeight='500'
				onClick={handleUpdateProfile}
			>
				Actualizar dirección Lightning
			</Button>
		</Card>
	);
}
