import {
	Box,
	Flex,
	Heading,
	Select,
	SimpleGrid,
	useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getAllBrand } from 'api/brand';
import Brand from 'components/card/Brand';
import ResultMessage from 'components/modal/ResultMessage';
import { ALERT } from 'constant/messages';
import { SearchBar } from 'views/client/brands/components/Search';

export default function BrandDashboard() {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const [brandData, setBrandData] = useState([]);
	const [search, setSearch] = useState('');
	const [selectedCategory, setSelectedCategory] = useState('all');
	const [isLoading, setIsLoading] = useState(false);
	const [message, setMessage] = useState({
		state: false,
		title: '',
		subtitle: '',
	});

	const handleMessage = (state, msg, sub) => {
		setMessage({
			state: state,
			title: msg,
			subtitle: sub,
		});
	};

	const handleCloseModal = () => {
		setMessage({
			state: false,
			title: '',
			subtitle: '',
		});
	};

	const filteredBrands = brandData.filter(
		(brand) =>
			brand.brandName.toLowerCase().includes(search.toLowerCase()) &&
			(selectedCategory === 'all' ||
				brand?._categoryId?.name === selectedCategory)
	);

	const categories = [
		...new Set(brandData.map((brand) => brand?._categoryId?.name)),
	];

	useEffect(() => {
		document.querySelector('.scroll-body')?.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const fetchAllBrand = async () => {
			try {
				setIsLoading(true);
				const response = await getAllBrand(TOKEN);
				if (response.status === 200) {
					const result = await response.json();
					const resultData = result?.data?.data || [];
					setBrandData(resultData);
				} else {
					handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
				}
			} catch (error) {
				handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchAllBrand();
	}, []);

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const navigate = useNavigate();

	return (
		<Box pt={{ base: '80px', md: '80px', xl: '80px' }}>
			<Heading
				mb='20px'
				color={textColor}
				fontSize='2xl'
				ms='24px'
				fontWeight='700'
			>
				Tus marcas favoritas
			</Heading>
			<Flex pl='1rem' w='90%' mb='36px'>
				<SearchBar setSearch={setSearch} />
				<Select
					fontSize='sm'
					id='edit_product'
					variant='main'
					h='44px'
					maxh='44px'
					me={{ base: '10px', md: '20px' }}
					value={selectedCategory}
					onChange={(e) => setSelectedCategory(e.target.value)}
				>
					<option value='all'>Todas las categorías</option>
					{categories.map((category, index) => (
						<option key={index} value={category}>
							{category}
						</option>
					))}
				</Select>
			</Flex>

			<SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap='20px'>
				{!isLoading &&
					filteredBrands.map((brand) => {
						let image;
						try {
							image = require(`../../../assets/img/brands/${brand.image}`);
						} catch (error) {
							image = require('../../../assets/img/nfts/Nft5.png');
						}

						return (
							<Brand
								key={brand.id}
								brand={brand}
								image={image}
								navigate={() =>
									navigate('/client/dashboard/detail', {
										state: {
											brand: brand,
											brandImg: image,
											brandData: brandData,
										},
									})
								}
							/>
						);
					})}
			</SimpleGrid>
			<ResultMessage
				isOpen={message.state}
				onClose={handleCloseModal}
				message={message}
			/>
		</Box>
	);
}
