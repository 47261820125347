// Chakra imports
import { Flex, Image, useColorMode, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';

// Assets
import logoLightMode from 'assets/icon/bumbei_logo_BN-02.png';
import logoDarkMode from 'assets/icon/bumbei_logo_BN-04.png';

export function SidebarBrand(props) {
	const { mini, hovered } = props;

	let logoColor = useColorModeValue('navy.700', 'white');
	const { colorMode } = useColorMode();

	return (
		<Flex alignItems='center' flexDirection='column'>
			<Image
				ml={'-20px'}
				src={colorMode === 'light' ? logoLightMode : logoDarkMode}
			/>
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
