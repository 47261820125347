import { InfoOutlineIcon, LinkIcon } from '@chakra-ui/icons';
import {
	Flex,
	FormLabel,
	Input,
	InputGroup,
	InputLeftElement,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';

export default function LinkInput({ link, message, brand, handleChange }) {
	const brandColor = useColorModeValue('brand.500', 'white');
	const getStatusColor = (status) => {
		switch (status) {
			case 'red':
				return 'red.800';
			case 'green':
				return 'green.800';
			default:
				return brandColor;
		}
	};

	return (
		<Flex pb={3} direction='column'>
			<FormLabel
				display='flex'
				flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
				alignItems='center'
				gap='0.5rem'
			>
				<Flex display='flex' alignItems='center' gap='0.5rem'>
					<InfoOutlineIcon display={{ base: 'none', md: 'block' }} />
					<Text> Asegúrate que tu enlace empiece con </Text>
				</Flex>
				<Text>
					<strong>{`${brand?.domain}`}</strong>
				</Text>
			</FormLabel>
			<InputGroup>
				<InputLeftElement pointerEvents='none'>
					<LinkIcon color={getStatusColor()} />
				</InputLeftElement>
				<Input
					borderColor={getStatusColor()}
					borderWidth={
						message.status === 'red' || message.status === 'green' ? 2 : 1
					}
					value={link.userLink}
					onChange={handleChange}
					borderRadius='6px'
					height='40px'
					isRequired={true}
					fontSize='md'
					placeholder='Copia aquí tu link...'
					variant='auth'
					size='lg'
				/>
			</InputGroup>
		</Flex>
	);
}
