import { CopyIcon } from '@chakra-ui/icons';
import { Badge, Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

export default function LinkSuccess({
	handleCopyLink,
	setTooltip,
	tooltip,
	linkSuccess,
}) {
	return (
		<>
			<Flex direction='column' alignItems='center'>
				<Text fontSize='2xl' fontWeight='semibold'>
					Tu enlace Bumbei
				</Text>
			</Flex>
			<Tooltip
				label={tooltip.label}
				aria-label={tooltip.label}
				bg='black'
				color='white'
				padding='10px'
				borderRadius='10px'
				isOpen={tooltip.isOpen}
			>
				<Badge
					onMouseEnter={() =>
						setTooltip((prevState) => ({
							...prevState,
							isOpen: true,
						}))
					}
					onMouseLeave={() =>
						setTooltip((prevState) => ({
							...prevState,
							isOpen: false,
						}))
					}
					my={5}
					py={5}
					w='100%'
					colorScheme='gray'
					textTransform='unset'
					fontSize='md'
					whiteSpace='normal'
					lineHeight={1.5}
					cursor={'pointer'}
					transition='all 0.3s'
					_hover={{ transform: 'scale(1.05)', cursor: 'pointer' }}
					onClick={(e) => handleCopyLink(e)}
				>
					<CopyIcon mr={3} /> {linkSuccess.link}
				</Badge>
			</Tooltip>
			<NavLink to={'/client/links'}>
				<Button
					px='1rem'
					textDecoration='underline'
					bg='brand.700'
					color='white'
					cursor='pointer'
				>
					Ir a mis enlaces
				</Button>
			</NavLink>
		</>
	);
}
