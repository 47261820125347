// Chakra imports
import { Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

export default function Banner(props) {
	const { desc } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	return (
		<Card p='30px' mb={{ base: '20px', '2xl': '20px' }}>
			<Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
				Acerca de la marca
			</Text>
			<Text color={textColor} fontSize='md' fontWeight='500'>
				{desc}
			</Text>
			<Text color={textColor} fontSize='md' fontWeight='500'>
				Genera tu enlace bumbei y compártelo con tu comunidad. Recibirás a
				cambio un porcentaje en Bitcoin.
			</Text>
		</Card>
	);
}
