import { useColorModeValue } from '@chakra-ui/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { useMemo, useState } from 'react';
import { formatDataType } from 'utils/format';

export default function DataTable({ columns, rows, sortModel }) {
	const [pageSize, setPageSize] = useState(5);
	const balanceBg = useColorModeValue('brand.900', '#4B66B8');

	const textPrimaryColor = useColorModeValue('#1A202C', '#FFFF');
	const textSecondaryColor = useColorModeValue('gray.700', '#FFFF');
	const mainColor = useColorModeValue('#3a3a3c', '#FFFF');
	const secondaryColor = useColorModeValue('#ff9800', '#FFFF');
	const theme = createTheme({
		palette: {
			primary: {
				main: mainColor,
			},
			secondary: {
				main: secondaryColor,
			},
			text: {
				primary: textPrimaryColor,
				secondary: textSecondaryColor,
			},
		},
		components: {
			MuiDataGrid: {
				styleOverrides: {
					root: {
						border: 'none',
						padding: '1rem',
						boxShadow: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
						borderRadius: '2rem',
						backgroundColor: balanceBg,
						fontWeight: 600,
						fontFamily: 'inherit',
					},
					columnHeaders: {
						color: textPrimaryColor,
						backgroundColor: balanceBg,
						fontWeight: 600,
						border: 'none',
					},
				},
			},
			MuiPopover: {
				styleOverrides: {
					paper: {
						backgroundColor: balanceBg,
						color: textPrimaryColor,
					},
				},
			},
		},
	});
	const processedRows = useMemo(() => {
		if (rows?.length > 0 && columns?.length > 0) {
			return rows.map((row) => {
				const formattedRow = { ...row };
				columns?.forEach((column) => {
					if (formattedRow[column.field] !== undefined) {
						formattedRow[column.field] = formatDataType(
							column.dataType,
							formattedRow[column.field]
						);
					}
				});
				return formattedRow;
			});
		}
		return [];
	}, [rows, columns]);

	return (
		<ThemeProvider theme={theme}>
			<div
				style={{
					height: '80vh',
					width: '100%',
					paddingLeft: '20px',
					paddingRight: '20px',
				}}
			>
				<DataGrid
					sortModel={sortModel}
					rows={processedRows}
					columns={columns}
					localeText={esES.components.MuiDataGrid.defaultProps.localeText}
					headerFilters
					disableDensitySelector
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[5, 10, 20]}
					pagination
					slots={{
						toolbar: GridToolbar,
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
						},
					}}
					sx={{
						'& .MuiDataGrid-container--top [role=row]': {
							backgroundColor: balanceBg,
							color: textPrimaryColor,
						},
						'& .MuiButtonBase-root.MuiIconButton-root': {
							color: textPrimaryColor,
						},
					}}
				/>
			</div>
		</ThemeProvider>
	);
}
