import {
	Button,
	FormControl,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { addLink, getShortLink } from 'api/link';
import LinkForm from 'components/modal/linkBuilder/components/LinkForm';
import LinkInput from 'components/modal/linkBuilder/components/LinkInput';
import LinkSuccess from 'components/modal/linkBuilder/components/LinkSuccess';
import { ALERT } from 'constant/messages';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function LinkBuilder({
	isOpen,
	onClose,
	title,
	subtitle,
	brand,
}) {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);
	const [linkSuccess, setLinkSuccess] = useState({ state: false, link: '' });
	const [tooltip, setTooltip] = useState({
		isOpen: false,
		label: 'Copiar a portapapeles',
	});
	const [link, setLink] = useState({
		userLink: '',
		customLink: '',
		campaign: '',
		tracker: '',
		bumbeiId: '',
	});
	const [message, setMessage] = useState({
		state: false,
		status: '',
		description: '',
	});
	const [loading, setLoading] = useState(false);

	const handleChange = (e) => {
		setLink((prevState) => ({ ...prevState, userLink: e.target.value }));
		let inputLink = e.target.value;

		if (inputLink && inputLink.includes(brand.domain)) {
			function generateUniqueId() {
				return Date.now();
			}

			let timeStamp = generateUniqueId();
			let concatenatedString = USERID + timeStamp.toString();
			let uniqueBumbeiId = Number(concatenatedString);

			let newCustomLink = brand.link
				.replace('{user}', uniqueBumbeiId)
				.replace('{encoded_url}', inputLink);

			setMessage({
				state: true,
				status: 'green',
				description:
					'¡Enlace válido! Genera tu enlace y compártelo con tu comunidad.',
			});
			setLink((prevState) => ({
				...prevState,
				customLink: newCustomLink,
				bumbeiId: uniqueBumbeiId,
			}));
		} else if (inputLink) {
			setMessage({
				state: true,
				status: 'red',
				description: 'Favor de ingresar un enlace válido.',
			});
		} else if (inputLink === '') {
			handleFocus();
		}
	};

	const handleFocus = () => {
		setMessage({
			state: false,
			status: '',
			description: '',
		});
		setTooltip({ isOpen: false, label: 'Copiar a portapapeles' });
		setLink((prevState) => ({ ...prevState, customLink: '' }));
	};

	const handleCopyLink = (e) => {
		navigator.clipboard
			.writeText(e.target.textContent)
			.then(() => {
				setTooltip({ isOpen: true, label: '¡Enlace copiado!' });
				setTimeout(() => {
					setTooltip({ isOpen: false, label: 'Copiar a portapapeles' });
				}, 1500);
			})
			.catch((err) => {
				console.error('Failed to copy text: ', err);
			});
	};

	const handleCloseModal = () => {
		onClose();
		setMessage({
			state: false,
			status: '',
			description: '',
		});
		setTooltip({
			isOpen: false,
			label: 'Copiar a portapapeles',
		});
		setLink({
			userLink: '',
			customLink: '',
			campaign: '',
			tracker: '',
		});
		setLinkSuccess(false);
	};

	const handleMessage = (status, description) => {
		setMessage({ state: 'true', status: status, description: description });
	};

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const shortLink = await getShortLink(TOKEN, link?.customLink);

			let linkObj = {
				bumbeiId: link?.bumbeiId,
				shortLink: shortLink,
				link: link?.customLink,
				advertiserId: brand?.advertiserId,
				networkId: brand?.networkId,
				userId: USERID,
				brandId: brand?.id,
				domain: link?.userLink,
				status: 'activo',
				type: 'client',
				campaign: link?.campaign,
				tracker: link?.tracker,
			};
			const response = await addLink(TOKEN, USERID, linkObj);
			const result = await response.json();

			if (response.status === 200 && result.status === 'SUCCESS') {
				setLinkSuccess({
					state: true,
					link: shortLink ? shortLink : link?.customLink,
				});
			} else {
				handleMessage('red', ALERT.ERROR_TITLE + '. ' + ALERT.ERROR_SUBTITLE);
			}
		} catch (error) {
			console.error(error);
			handleMessage('red', ALERT.ERROR_TITLE + '. ' + ALERT.ERROR_SUBTITLE);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={handleCloseModal}>
			<ModalOverlay />
			<ModalContent maxWidth={'700px'}>
				<ModalHeader
					display='flex'
					alignItems='center'
					gap='10px'
					pt='50px'
					textAlign='left'
				>
					{title}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Text pb={10}>{subtitle}</Text>
					<FormControl px={{ sm: 0, md: 5 }}>
						{!linkSuccess.state && (
							<>
								<LinkInput
									link={link}
									brand={brand}
									handleFocus={handleFocus}
									message={message}
									handleChange={handleChange}
								/>
								<LinkForm
									link={link}
									message={message}
									setLink={setLink}
									handleSubmit={handleSubmit}
									loading={loading}
								/>
							</>
						)}
						{linkSuccess.state && message.status !== 'red' && (
							<LinkSuccess
								setTooltip={setTooltip}
								tooltip={tooltip}
								handleCopyLink={handleCopyLink}
								linkSuccess={linkSuccess}
							/>
						)}
					</FormControl>
				</ModalBody>
				<ModalFooter gap='20px'>
					<Button onClick={handleCloseModal}>Cerrar</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
