import { Chip } from '@mui/material';
import CopyableChip from 'components/table/copyableChip';
import { capitalizeEveryWord } from 'utils/format';

export const columnsDataLinks = [
	{
		headerName: 'ID',
		field: 'id',
		width: 150,
	},
	{
		headerName: 'FECHA',
		field: 'createdAt',
		dataType: 'date',
		width: 200,
	},
	{
		headerName: 'ENLACE CORTO',
		field: 'shortLink',
		width: 300,
		renderCell: (params) =>
			params.value ? <CopyableChip value={params.value} /> : null,
	},
	{
		headerName: 'ENLACE ORIGINAL',
		field: 'domain',
		width: 300,
		renderCell: (params) =>
			params.value ? (
				<CopyableChip value={params.value} copyable={false} />
			) : null,
	},
	{
		headerName: 'MARCA',
		field: '_brandId',
		width: 150,
		valueGetter: (params) => params.brandName,
	},
	{
		headerName: 'ESTATUS',
		field: 'status',
		width: 150,
		renderCell: (params) =>
			params.value ? (
				<Chip
					label={capitalizeEveryWord(params.value)}
					color={'default'}
					variant='solid'
				/>
			) : null,
	},
	{
		headerName: 'CAMPAÑA',
		field: 'campaign',
		width: 150,
		renderCell: (params) =>
			params.value ? (
				<Chip
					label={capitalizeEveryWord(params.value)}
					color={'primary'}
					variant='filled'
				/>
			) : null,
	},
	{
		headerName: 'TRACKER',
		field: 'tracker',
		width: 250,
	},
];
