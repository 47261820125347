// Chakra imports
import { Box, Flex } from '@chakra-ui/react';
import { getAllLink } from 'api/link';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// Assets
import {
	getConversionPendingCount,
	getConversionSuccessCount,
} from 'api/conversion';
import profileAvatar from 'assets/icon/bumbei_iconoapp_RGB-01.png';
import ResultMessage from 'components/modal/ResultMessage';
import { ALERT } from 'constant/messages';
import Banner from 'views/client/welcome/components/Banner';

export default function Welcome() {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);

	const [loading, setLoading] = useState(false);
	const [linkData, setLinkData] = useState([]);
	const [conversionSuccessCount, setConversionSuccessCount] = useState([]);
	const [conversionPendingCount, setConversionPendingCount] = useState([]);

	const [message, setMessage] = useState({
		state: false,
		status: '',
		description: '',
	});

	const handleMessage = (state, title, subtitle) => {
		setMessage({ state: state, title: title, subtitle: subtitle });
	};

	const handleCloseModal = () => {
		setMessage({
			state: false,
			title: '',
			subtitle: '',
		});
	};

	useEffect(() => {
		document.querySelector('.scroll-body')?.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);

				const [linksResponse, successResponse, pendingResponse] =
					await Promise.all([
						getAllLink(TOKEN, USERID),
						getConversionSuccessCount(TOKEN, USERID),
						getConversionPendingCount(TOKEN, USERID),
					]);

				const [linksResult, successResult, pendingResult] = await Promise.all([
					linksResponse.json(),
					successResponse.json(),
					pendingResponse.json(),
				]);

				if (linksResponse.status === 200 && linksResult.status === 'SUCCESS') {
					const linksData = linksResult?.data?.data || [];
					setLinkData(linksData);
				} else {
					handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
				}

				if (
					successResponse.status === 200 &&
					successResult.status === 'SUCCESS'
				) {
					const successData = successResult?.data?.data || [];
					setConversionSuccessCount(successData);
				} else {
					handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
				}

				if (
					pendingResponse.status === 200 &&
					pendingResult.status === 'SUCCESS'
				) {
					const pendingData = pendingResult?.data?.data || [];
					setConversionPendingCount(pendingData);
				} else {
					handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
				}
			} catch (error) {
				console.error(error);
				handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	return (
		<Box pt={{ base: '80px', md: '80px', xl: '80px' }}>
			<Box mb='20px' display={{ base: 'block', lg: 'grid' }}>
				<Flex flexDirection='column'>
					<Banner profile={profileAvatar} links={linkData.length} />
				</Flex>
			</Box>
			<ResultMessage
				isOpen={message.state}
				onClose={handleCloseModal}
				message={message}
			/>
		</Box>
	);
}
