// Chakra imports
import { Box, Image } from '@chakra-ui/react';
// Assets

export default function Banner(props) {
	const { image } = props;

	return (
		<Box maxW='100%' mb='20px' position='relative'>
			<Image
				src={image}
				objectFit='cover'
				w='100%'
				h='100%'
				maxH='300px'
				borderRadius='20px'
			/>
		</Box>
	);
}
