// Chakra imports
import {
	Box,
	Flex,
	Heading,
	SimpleGrid,
	useColorModeValue,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import Information from 'views/client/profile/components/Information';
import Password from 'views/client/profile/components/Password';
import Profile from 'views/client/profile/components/Profile';

export default function Settings() {
	useEffect(() => {
		document.querySelector('.scroll-body')?.scrollTo(0, 0);
	}, []);
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	return (
		<Box pt={{ base: '80px', md: '80px', xl: '80px' }}>
			<SimpleGrid
				mb='20px'
				columns={{ sm: 1, md: 1, lg: 1 }}
				spacing={{ base: '20px' }}
			>
				<Heading color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
					Tu perfil
				</Heading>
				<Flex direction='column'>
					<Profile />
					<Information />
					<Password />
				</Flex>
			</SimpleGrid>
		</Box>
	);
}
