import {
	Button,
	Flex,
	Heading,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { getAllLink } from 'api/link';
import Card from 'components/card/Card';
import ResultMessage from 'components/modal/ResultMessage';
import DataTable from 'components/table/dataTable';
import { ALERT } from 'constant/messages';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { columnsDataLinks } from 'views/client/links/variable/columnsDataLinks';

export default function Links() {
	const textColor = useColorModeValue('gray.900', 'gray.100');
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [linkData, setLinkData] = useState([]);
	const [message, setMessage] = useState({
		state: false,
		status: '',
		description: '',
	});

	const handleMessage = (state, title, subtitle) => {
		setMessage({ state: state, title: title, subtitle: subtitle });
	};

	const handleCloseModal = () => {
		setMessage({
			state: false,
			title: '',
			subtitle: '',
		});
	};

	useEffect(() => {
		document.querySelector('.scroll-body')?.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const fetchLinks = async () => {
			try {
				setLoading(true);

				const response = await getAllLink(TOKEN, USERID);
				const result = await response.json();

				if (response.status === 200 && result.status === 'SUCCESS') {
					const resultData = result?.data?.data || [];
					setLinkData(resultData);
				} else {
					handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
				}
			} catch (error) {
				console.error(error);
				handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
			} finally {
				setLoading(false);
			}
		};

		fetchLinks();
	}, []);

	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
				<Heading
					mb='20px'
					color={textColor}
					fontSize='2xl'
					ms='24px'
					fontWeight='700'
				>
					Tus enlaces Bumbei
				</Heading>
				{!loading && linkData.length > 0 && (
					<DataTable rows={linkData} columns={columnsDataLinks} />
				)}
				{!loading && linkData.length === 0 && (
					<Flex
						flexDirection={'column'}
						p={'3rem'}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<Text textAlign={'center'}>
							Aquí podrás encontrar todos los enlaces que generes con Bumbei.{' '}
							<br />
							¡Explora las marcas que tenemos para ti!
						</Text>
						<Button
							variant='brand'
							mt={'25px'}
							onClick={() => navigate('/client/dashboard')}
						>
							Explorar
						</Button>
					</Flex>
				)}
			</Card>
			<ResultMessage
				isOpen={message.state}
				onClose={handleCloseModal}
				message={message}
			/>
		</Flex>
	);
}
