import { CopyIcon } from '@chakra-ui/icons';
import { Badge, Tooltip, useClipboard } from '@chakra-ui/react';
import { useState } from 'react';

const CopyableChip = ({ value, copyable = true }) => {
	const { onCopy } = useClipboard(value);
	const [tooltip, setTooltip] = useState({
		isOpen: false,
		label: 'Copiar a portapapeles',
	});

	const handleCopyLink = (e) => {
		navigator.clipboard
			.writeText(e.target.textContent)
			.then(() => {
				setTooltip({ isOpen: true, label: '¡Enlace copiado!' });
				setTimeout(() => {
					setTooltip({ isOpen: false, label: 'Copiar a portapapeles' });
				}, 1500);
			})
			.catch((err) => {
				console.error('Failed to copy text: ', err);
			});
	};

	return (
		<Tooltip
			label={tooltip.label}
			aria-label={tooltip.label}
			bg='black'
			color='white'
			padding='10px'
			borderRadius='10px'
			isOpen={tooltip.isOpen && copyable}
			placement='top'
		>
			<Badge
				onMouseEnter={() =>
					setTooltip((prevState) => ({
						...prevState,
						isOpen: true,
					}))
				}
				onMouseLeave={() =>
					setTooltip((prevState) => ({
						...prevState,
						isOpen: false,
					}))
				}
				w='100%'
				colorScheme='gray'
				textTransform='unset'
				fontSize='sm'
				cursor={'pointer'}
				transition='all 0.3s'
				_hover={copyable && { transform: 'scale(1.05)', cursor: 'pointer' }}
				onClick={(e) => (copyable ? handleCopyLink(e) : null)}
			>
				{copyable && <CopyIcon mr={2} />} {value}
			</Badge>
		</Tooltip>
	);
};

export default CopyableChip;
