import {
	Box,
	Button,
	Flex,
	Image,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
// Assets
export default function Brand(props) {
	const { image, brand, navigate } = props;
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorBid = useColorModeValue('brand.500', 'white');
	return (
		<Card p='20px'>
			<Flex direction={{ base: 'column' }} justify='center'>
				<Box mb={{ base: '20px', '2xl': '20px' }} position='relative'>
					<Image
						src={image}
						w={{ base: '100%' }}
						h={{ base: '220px' }}
						borderRadius='20px'
						objectFit={'cover'}
					/>
				</Box>
				<Flex flexDirection='column' justify='space-between' h='100%'>
					<Flex
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row',
						}}
						mb='auto'
					>
						<Flex direction='column'>
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg',
								}}
								mb='5px'
								fontWeight='bold'
								me='14px'
							>
								{brand.brandName}
							</Text>
							<Text
								color='secondaryGray.600'
								fontSize={{
									base: 'sm',
								}}
								fontWeight='400'
								me='14px'
							>
								{brand?._categoryId?.name}
							</Text>
						</Flex>
					</Flex>
					<Flex
						align={'center'}
						justify='space-between'
						direction={{
							base: 'column',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row',
						}}
						mt='25px'
						gap={{ base: '15px', md: '10px' }}
					>
						<Text fontWeight='700' fontSize='sm' color={textColorBid}>
							Comisión: {`${brand.rate}%`}
						</Text>

						<Button
							onClick={navigate}
							variant='darkBrand'
							transition='all 0.3s'
							_hover={{ transform: 'scale(1.05)' }}
							color='white'
							fontSize='sm'
							fontWeight='500'
							borderRadius='70px'
							px='24px'
							py='5px'
							mt={{
								base: '0px',
								md: '10px',
								lg: '0px',
								xl: '10px',
								'2xl': '0px',
							}}
						>
							Ver más{' '}
						</Button>
					</Flex>
				</Flex>
			</Flex>
		</Card>
	);
}
