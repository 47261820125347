// Chakra Imports
import {
	Badge,
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Icon,
	Image,
	SimpleGrid,
	Text,
	useColorMode,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react';
import Contrast from 'assets/img/layout/Contrast.png';
import ContrastBlock from 'assets/img/layout/ContrastBlock.png';
import ContrastBlockDark from 'assets/img/layout/ContrastBlockDark.png';
import ContrastDark from 'assets/img/layout/ContrastDark.png';
import Dark from 'assets/img/layout/Dark.png';
import DefaultSidebar from 'assets/img/layout/DefaultSidebar.png';
import DefaultSidebarDark from 'assets/img/layout/DefaultSidebarDark.png';
import Light from 'assets/img/layout/Light.png';
import MiniSidebar from 'assets/img/layout/MiniSidebar.png';
import MiniSidebarDark from 'assets/img/layout/MiniSidebarDark.png';
import { useEffect, useState } from 'react';
// Assets
import { HSeparator } from 'components/separator/Separator';
import { MdRefresh, MdSettings } from 'react-icons/md';
import ConfiguratorRadio from './ConfiguratorRadio';
export default function HeaderLinks(props) {
	const { theme, setTheme } = props;
	//eslint-disable-next-line
	const { colorMode, toggleColorMode } = useColorMode();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [active, setActive] = useState('Purple');
	const [contrast, setContrast] = useState(false);
	// const [fullscreen, setFullscreen] = useState(false);
	// Chakra Color Mode
	const resetTheme = () => {
		const newTheme = {
			...theme,
			colors: {
				...theme.colors,
				brand: {
					50: '#EFEBFF',
					100: '#E9E3FF',
					200: '#422AFB',
					300: '#422AFB',
					400: '#7551FF',
					500: '#422AFB',
					600: '#3311DB',
					700: '#02044A',
					800: '#190793',
					900: '#11047A',
				},
				brandScheme: {
					50: '#EFEBFF',
					100: '#E9E3FF',
					200: '#7551FF',
					300: '#7551FF',
					400: '#7551FF',
					500: '#422AFB',
					600: '#3311DB',
					700: '#02044A',
					800: '#190793',
					900: '#02044A',
				},
				brandTabs: {
					50: '#EFEBFF',
					100: '#E9E3FF',
					200: '#422AFB',
					300: '#422AFB',
					400: '#422AFB',
					500: '#422AFB',
					600: '#3311DB',
					700: '#02044A',
					800: '#190793',
					900: '#02044A',
				},
				background: {
					100: '#FFFFFF',
					900: '#0b1437',
				},
			},
		};
		setTheme(newTheme);
	};

	const changeBgDefault = () => {
		const newTheme = {
			...theme,
			colors: {
				...theme.colors,
				background: {
					100: '#FFFFFF',
					900: '#0b1437',
				},
			},
		};
		setTheme(newTheme);
	};
	const changeBgContrast = () => {
		const newTheme = {
			...theme,
			colors: {
				...theme.colors,
				background: {
					100: '#F4F7FE',
					900: '#070f2e',
				},
			},
		};
		setTheme(newTheme);
	};
	const ContrastBlockImage = useColorModeValue(
		ContrastBlock,
		ContrastBlockDark
	);
	const ContrastImage = useColorModeValue(Contrast, ContrastDark);
	const MiniSidebarImage = useColorModeValue(MiniSidebar, MiniSidebarDark);
	const DefaultSidebarImage = useColorModeValue(
		DefaultSidebar,
		DefaultSidebarDark
	);
	const navbarIcon = useColorModeValue('gray.400', 'white');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const brandColor = useColorModeValue('brand.500', 'white');
	const bgBadge = useColorModeValue('brand.50', 'whiteAlpha.100');
	const bgContrast = useColorModeValue('secondaryGray.300', 'navy.900');
	const borderButton = useColorModeValue('secondaryGray.100', '#323B5D');
	const bgSeparator = useColorModeValue('secondaryGray.400', '#323B5D');
	const activeShadow = useColorModeValue(
		'0px 18px 40px rgba(112, 144, 176, 0.22)',
		'none'
	);
	const activeBg = useColorModeValue('#F7F9FF', 'whiteAlpha.100');
	const Bg = useColorModeValue('white', 'navy.700');
	const drawerBg = useColorModeValue('white', 'navy.800');

	const shadowBlock = useColorModeValue(
		'0px 6px 14px rgba(200, 207, 215, 0.6)',
		'none'
	);

	const fullscreenBorder = useColorModeValue(
		'secondaryGray.100',
		'whiteAlpha.200'
	);
	const fullscreenBg = useColorModeValue(
		'rgba(11,11,11,0)',
		'rgba(11,11,11,0)'
	);
	const configuratorShadow = useColorModeValue(
		'-20px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'-22px 32px 51px 4px #0B1437'
	);
	useEffect(() => {
		if (theme.colors.background[100] === '#FFFFFF') {
			setContrast(false);
		} else {
			setContrast(true);
		}
	}, [theme.colors.background]);
	const [isFullscreen, setIsFullscreen] = useState(false);

	// Watch for fullscreenchange
	useEffect(() => {
		function onFullscreenChange() {
			setIsFullscreen(Boolean(document.fullscreenElement));
		}

		document.addEventListener('fullscreenchange', onFullscreenChange);

		return () =>
			document.removeEventListener('fullscreenchange', onFullscreenChange);
	}, []);
	return (
		<>
			<Button
				variant='no-hover'
				bg='transparent'
				p='0px'
				minW='unset'
				minH='unset'
				h='18px'
				w='max-content'
				onClick={onOpen}
			>
				<Icon me='10px' h='18px' w='18px' color={navbarIcon} as={MdSettings} />
			</Button>
			<Drawer
				isOpen={isOpen}
				onClose={onClose}
				placement={'right'}
				blockScrollOnMount={false}
			>
				<DrawerOverlay />
				<DrawerContent
					boxShadow={configuratorShadow}
					w={{ base: 'calc(100vw - 32px)', md: '400px' }}
					maxW={{ base: 'calc(100vw - 32px)', md: '400px' }}
					ms={{
						base: '0px',
						sm: '16px',
					}}
					me={{
						base: '16px',
					}}
					my={{
						sm: '16px',
					}}
					borderRadius='16px'
					bg={drawerBg}
				>
					<DrawerHeader
						w={{ base: '100%', md: '400px' }}
						pt='24px'
						pb='0px'
						px='24px'
					>
						<Box
							position='absolute'
							cursor='pointer'
							top='13px'
							right='50px'
							onClick={() => {
								resetTheme();
								props.setMini(false);
							}}
						>
							<Icon h='22px' w='22px' color={textColor} as={MdRefresh} />
						</Box>
						<DrawerCloseButton color={textColor} />
						<Flex alignItems='center'>
							<Box>
								<Text color={textColor} fontSize='xl' fontWeight='700'>
									Configuración
								</Text>
								<Text
									display={'flex'}
									color='secondaryGray.600'
									fontSize='md'
									fontWeight='500'
								>
									Versión
									<Badge
										display='flex'
										colorScheme='horizonPurple'
										borderRadius='25px'
										bg={bgBadge}
										color='white'
										textTransform={'none'}
										letterSpacing='0px'
										px='10px'
										ms='6px'
									>
										1.0.0
									</Badge>
								</Text>
							</Box>
						</Flex>
						<HSeparator my='30px' bg={bgSeparator} />
					</DrawerHeader>
					<DrawerBody
						pt='0px'
						pb='24px'
						w={{ base: '100%', md: '400px' }}
						maxW='unset'
					>
						<Flex flexDirection='column'>
							<Text color={textColor} mb='12px' fontWeight={'700'}>
								Modo de color
							</Text>
							<SimpleGrid columns={2} gap='20px' mb='30px'>
								<ConfiguratorRadio
									onClick={colorMode === 'dark' ? toggleColorMode : null}
									active={colorMode === 'dark' ? false : true}
									label={<Text>Claro</Text>}
								>
									<Image
										src={Light}
										maxW={{ base: '100%', md: '130px' }}
										borderRadius='8px'
									/>
								</ConfiguratorRadio>
								<ConfiguratorRadio
									onClick={colorMode === 'light' ? toggleColorMode : null}
									active={colorMode === 'light' ? false : true}
									label={<Text>Oscuro</Text>}
								>
									<Image
										src={Dark}
										maxW={{ base: '100%', md: '130px' }}
										borderRadius='8px'
									/>
								</ConfiguratorRadio>
							</SimpleGrid>
							<Text color={textColor} mb='12px' fontWeight={'700'}>
								Contraste
							</Text>
							<SimpleGrid columns={2} gap='20px' mb='30px'>
								<ConfiguratorRadio
									onClick={() => changeBgDefault()}
									active={contrast === true ? false : true}
									label={<Text>Transparente</Text>}
								>
									<Flex
										pt='10px'
										ps='10px'
										bg='repeat'
										borderRadius='10px'
										border='1px solid'
										borderColor={borderButton}
										bgImage={`url(${ContrastImage})`}
										overflow='hidden'
									>
										<Image
											mt='auto'
											src={ContrastBlockImage}
											boxShadow={shadowBlock}
										/>
									</Flex>
								</ConfiguratorRadio>
								<ConfiguratorRadio
									onClick={() => changeBgContrast()}
									active={contrast === false ? false : true}
									label={<Text>Relleno</Text>}
								>
									<Flex
										pt='10px'
										ps='10px'
										borderRadius='10px'
										border='1px solid'
										borderColor={borderButton}
										bg={bgContrast}
										overflow='hidden'
									>
										<Image
											mt='auto'
											src={ContrastBlockImage}
											maxW={{ base: '100%', md: '130px' }}
											boxShadow={shadowBlock}
										/>
									</Flex>
								</ConfiguratorRadio>
							</SimpleGrid>
							<Text color={textColor} mb='12px' fontWeight={'700'}>
								Barra lateral
							</Text>
							<SimpleGrid columns={2} gap='20px' mb='30px'>
								<ConfiguratorRadio
									onClick={() => props.setMini(false)}
									active={props.mini === true ? false : true}
									label={<Text>Por defecto</Text>}
								>
									<Flex
										py='25px'
										px='18px'
										borderRadius='10px'
										border='1px solid'
										borderColor={borderButton}
										bg={bgContrast}
										overflow='hidden'
										maxW={{ base: '100%', md: '130px' }}
									>
										<Image
											src={DefaultSidebarImage}
											maxW={{ base: '100%', md: '96px' }}
											boxShadow={shadowBlock}
										/>
									</Flex>
								</ConfiguratorRadio>
								<ConfiguratorRadio
									onClick={() => props.setMini(true)}
									active={props.mini === false ? false : true}
									label={<Text>Minimizado</Text>}
								>
									<Flex
										py='27.5px'
										px='28px'
										maxW={{ base: '100%', md: '130px' }}
										borderRadius='10px'
										border='1px solid'
										borderColor={borderButton}
										bg={bgContrast}
										overflow='hidden'
									>
										<Image
											src={MiniSidebarImage}
											maxW={{ base: '100%', md: '75px' }}
											boxShadow={shadowBlock}
										/>
									</Flex>
								</ConfiguratorRadio>
							</SimpleGrid>
						</Flex>
						<HSeparator my='30px' bg={bgSeparator} />
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}
