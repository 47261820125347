import { Chip } from '@mui/material';
import { capitalizeEveryWord } from 'utils/format';

export const columnsDataWallet = [
	{
		headerName: 'ID',
		field: 'id',
		width: 150,
	},
	{
		headerName: 'FECHA',
		field: 'createdAt',
		dataType: 'date',
		width: 200,
	},
	{
		headerName: 'MARCA',
		field: '_brandId',
		width: 150,
		valueGetter: (params) => params.brandName,
	},
	{
		headerName: 'MONTO DE VENTA',
		field: 'networkAmountSale',
		width: 200,
	},
	{
		headerName: 'MONEDA DE VENTA',
		field: 'currencyFiat',
		width: 200,
	},
	{
		headerName: 'TASA DE CAMBIO A SATS',
		field: 'currencyRateExchange',
		width: 250,
	},
	{
		headerName: 'CASHBACK BUMBEI (SATS)',
		field: 'bumbeiAmountCashback',
		width: 200,
	},
	{
		headerName: 'CASHBACK BUMBEI (%)',
		field: 'bumbeiRateCashback',
		width: 200,
	},
	{
		headerName: 'ENLACE CORTO',
		field: '_linkId',
		width: 300,
		valueGetter: (params) => params.shortLink,
	},
	{
		headerName: 'ESTATUS',
		field: 'status',
		width: 250,
		renderCell: (params) =>
			params.value ? (
				<Chip
					label={capitalizeEveryWord(params.value)}
					color={'default'}
					variant='solid'
				/>
			) : null,
	},
];
