import API_URL from '../config';

export const getAllConversion = async (TOKEN, USERID) => {
	try {
		const response = await fetch(API_URL + 'client/api/v1/conversion/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				// prettier-ignore
				'Authorization': 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				options: {
					paginate: 100,
					sort: { createdAt: -1 },
					include: [
						{
							model: 'Link',
							attributes: ['shortLink', 'domain'],
							as: '_linkId',
						},
						{
							model: 'Brand',
							attributes: ['brandName'],
							as: '_brandId',
						},
					],
					where: { userId: USERID },
				},
				isCountOnly: false,
			}),
		});
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const getConversionPendingCount = async (TOKEN, USERID) => {
	try {
		const response = await fetch(API_URL + 'client/api/v1/conversion/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				// prettier-ignore
				'Authorization': 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				options: {
					where: { userId: USERID, status: 'Venta registrada' },
				},
				isCountOnly: false,
			}),
		});
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const getConversionSuccessCount = async (TOKEN, USERID) => {
	try {
		const response = await fetch(API_URL + 'client/api/v1/conversion/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				// prettier-ignore
				'Authorization': 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				options: {
					where: { userId: USERID, status: 'Conversión exitosa' },
				},
				isCountOnly: false,
			}),
		});
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};
