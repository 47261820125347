// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
import Footer from 'components/footer/FooterAuthCentered';
import Navbar from 'components/navbar/NavbarAuth';
import PropTypes from 'prop-types';

function AuthCentered(props) {
	//eslint-disable-next-line
	const { children, title, description, image, cardTop, cardBottom } = props;
	const textColor = useColorModeValue('navy.700', 'white');
	return (
		<Flex
			direction='column'
			alignSelf='center'
			justifySelf='center'
			justifyContent='space-between'
			overflow='hidden'
			mx={{ base: '10px', lg: '0px' }}
			minH='100vh'
		>
			<FixedPlugin />
			<Navbar color={textColor} />
			<Card
				w={{ base: '100%', md: 'max-content' }}
				h='max-content'
				mx='auto'
				maxW='100%'
				mb={cardBottom}
				p={{ base: '10px', md: '50px' }}
				pt={{ base: '10px' }}
			>
				{title && description ? (
					<Flex
						direction='column'
						textAlign='center'
						justifyContent='center'
						align='center'
						mb='30px'
					>
						<Text fontSize='4xl' color='white' fontWeight='bold'>
							{title}
						</Text>
						<Text
							fontSize='md'
							color='white'
							fontWeight='normal'
							mt='10px'
							mb='26px'
							w={{ base: '90%', sm: '60%', lg: '40%', xl: '333px' }}
						>
							{description}
						</Text>
					</Flex>
				) : null}
				{children}
			</Card>
			<Footer />
		</Flex>
	);
}
// PROPS

AuthCentered.propTypes = {
	description: PropTypes.string,
	title: PropTypes.string,
	image: PropTypes.any,
};

export default AuthCentered;
