import API_URL from '../config';

export const getAllNotifications = async (TOKEN, USERID) => {
	try {
		const response = await fetch(API_URL + 'client/api/v1/notification/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				// prettier-ignore
				'Authorization': 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				options: {
					paginate: 100,
					sort: { id: -1 },
					where: { recipient: 'client', userId: USERID },
				},
				isCountOnly: false,
			}),
		});
		const result = await response.json();
		const resultData = result?.data?.data;
		return resultData;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const countPendingNotifications = async (TOKEN, USERID) => {
	try {
		const response = await fetch(API_URL + 'client/api/v1/notification/count', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				// prettier-ignore
				'Authorization': 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				where: { recipient: 'client', userId: USERID, viewed: false },
			}),
		});
		const result = await response.json();
		const resultData = result?.data?.totalRecords;
		return resultData;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const setAsViewed = async (TOKEN, id) => {
	try {
		await fetch(API_URL + 'client/api/v1/notification/partial-update/' + id, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				// prettier-ignore
				'Authorization': 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				viewed: true,
			}),
		});
	} catch (error) {
		console.error(error);
	}
};

export const setAllAsViewed = async (TOKEN, USERID) => {
	try {
		await fetch(API_URL + 'client/api/v1/notification/updateBulk', {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				// prettier-ignore
				'Authorization': 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				filter: { recipient: 'client', userId: USERID, viewed: false },
				data: { viewed: true },
			}),
		});
	} catch (error) {
		console.error(error);
	}
};
