import API_URL from '../config';

export const getAllLink = async (TOKEN, USERID) => {
	try {
		const response = await fetch(API_URL + 'client/api/v1/link/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				// prettier-ignore
				'Authorization': 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				options: {
					paginate: 100,
					sort: { createdAt: -1 },
					include: [
						{
							model: 'Brand',
							attributes: ['brandName'],
							as: '_brandId',
						},
					],
					where: { userId: USERID, type: 'client' },
				},
				isCountOnly: false,
			}),
		});
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const addLink = async (TOKEN, USERID, link) => {
	try {
		const response = await fetch(API_URL + 'client/api/v1/link/create', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				// prettier-ignore
				'Authorization': 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				bumbeiId: link.bumbeiId,
				shortLink: link.shortLink,
				link: link.link,
				advertiserId: link.advertiserId,
				networkId: link.networkId,
				userId: USERID,
				brandId: link.brandId,
				domain: link.domain,
				status: link.status,
				campaign: link.campaign,
				type: link.type,
				tracker: link.tracker,
				createdAt: new Date(),
				createdBy: USERID,
			}),
		});
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const getShortLink = async (TOKEN, originalURL) => {
	try {
		const responseShortLink = await fetch(
			API_URL + 'client/api/v1/link/getShortLink',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					link: originalURL,
				}),
			}
		);
		const shortLinkResponse = await responseShortLink.json();
		const shortLink = shortLinkResponse?.data;

		return shortLink;
	} catch (error) {
		console.error('Error:', error);
	}
};
