import {
	Avatar,
	Button,
	Flex,
	Heading,
	Icon,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import Card from 'components/card/Card.js';
import { VSeparator } from 'components/separator/Separator';
import { useEffect, useState } from 'react';
import { MdVerified } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

export default function Banner(props) {
	const { links, profile } = props;

	const navigate = useNavigate();
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorLink = useColorModeValue('blue.500', 'white');
	const balanceBg = useColorModeValue('brand.900', 'navy.900');
	const {
		userFirstName,
		userLastName,
		userEmail,
		userBalanceAvailable,
		userBalancePending,
		userBalanceTotal,
	} = useSelector((state) => state.user);

	const [mxnValue, setMxnValue] = useState(0);
	const [usdValue, setUsdValue] = useState(0);
	const [currentCurrency, setCurrentCurrency] = useState('SATS');

	useEffect(() => {
		const fetchConversionRates = async () => {
			try {
				const response = await fetch(
					'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=mxn,usd'
				);
				const data = await response.json();
				const conversionRateMxn = data.bitcoin.mxn;
				const conversionRateUsd = data.bitcoin.usd;
				setMxnValue(conversionRateMxn);
				setUsdValue(conversionRateUsd);
			} catch (error) {
				console.error('Error fetching conversion rates:', error);
			}
		};

		fetchConversionRates();
	}, []);

	const satoshiToMxn = (sats) => {
		const btcValue = sats / 100_000_000;
		const mxnValueFormatted = (btcValue * mxnValue).toFixed(2);
		return new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(mxnValueFormatted);
	};

	const satoshiToUsd = (sats) => {
		const btcValue = sats / 100_000_000;
		const usdValueFormatted = (btcValue * usdValue).toFixed(2);
		return new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(usdValueFormatted);
	};

	const satoshiToBtc = (sats) => {
		return (sats / 100_000_000).toFixed(8);
	};

	const handleCurrencyChange = () => {
		if (currentCurrency === 'SATS') {
			setCurrentCurrency('USD');
		} else if (currentCurrency === 'USD') {
			setCurrentCurrency('BTC');
		} else if (currentCurrency === 'MXN') {
			setCurrentCurrency('SATS');
		} else {
			setCurrentCurrency('MXN');
		}
	};

	return (
		<Flex
			mb={{ base: '20px', '2xl': '20px' }}
			m={{ base: '0' }}
			justifyContent='center'
			align='center'
			direction='column'
			w={{ base: 'auto', md: '100%' }}
			mx='1rem'
		>
			<Avatar
				src={profile}
				h={{ base: '70px', md: '170px' }}
				w={{ base: '70px', md: '170px' }}
				mb='20px'
			/>
			<Heading
				color={textColor}
				fontSize={{ base: '32px', md: '54px' }}
				fontWeight='700'
				mb='15px'
				lineHeight='100%'
				textAlign='center'
			>
				Hola, {userFirstName ? `${userFirstName} ${userLastName}` : 'friend'}
			</Heading>
			{userEmail && (
				<Flex align='center' mb='40px'>
					<Text color='secondaryGray.600' fontSize='lg' fontWeight='400'>
						<Text
							as='span'
							color={textColorLink}
							fontSize='lg'
							fontWeight='500'
							me='4px'
						>
							{userEmail}
						</Text>
					</Text>
					<Icon as={MdVerified} h='16px' w='16px' color='blue.500' mt='3px' />
				</Flex>
			)}
			<NavLink to={'/client/links'}>
				<Button
					px='1rem'
					textDecoration='underline'
					bg='brand.700'
					color='white'
					cursor='pointer'
				>
					Mis enlaces Bumbei
				</Button>
			</NavLink>
			<Card maxW='100%' w='900px' py='40px' mb='40px'>
				<Flex
					justify='space-between'
					p='20px'
					mb='20px'
					borderRadius='16px'
					bgColor={balanceBg}
					bgImage={balanceImg}
					bgPosition='right'
					bgSize='cover'
					transition='all 0.3s'
					_hover={{ transform: 'scale(1.01)' }}
					cursor='pointer'
					onClick={handleCurrencyChange}
				>
					<Flex align='center' justify='space-between' w='100%'>
						<Flex flexDirection='column'>
							<Text color='white' fontSize='sm' fontWeight='500' mb={2}>
								Balance total
							</Text>
							<Text
								color='white'
								fontSize={{ base: '24px', md: '34px' }}
								fontWeight='700'
								lineHeight='100%'
							>
								{currentCurrency === 'SATS' &&
									`${
										Intl.NumberFormat('en-US', {
											minimumFractionDigits: 0,
											maximumFractionDigits: 0,
										}).format(userBalanceTotal) || '0'
									} SATS`}
								{currentCurrency === 'USD' &&
									`$${satoshiToUsd(userBalanceTotal)} USD`}
								{currentCurrency === 'BTC' &&
									`${satoshiToBtc(userBalanceTotal)} BTC`}
								{currentCurrency === 'MXN' &&
									`$${satoshiToMxn(userBalanceTotal)} MXN`}
							</Text>
							<Text
								mt={1}
								color='white'
								fontSize='14px'
								fontWeight='300'
								lineHeight='100%'
							>
								{currentCurrency === 'MXN'
									? `${
											Intl.NumberFormat('en-US', {
												minimumFractionDigits: 0,
												maximumFractionDigits: 0,
											}).format(userBalanceTotal) || '0'
									  } SATS`
									: `$${satoshiToMxn(userBalanceTotal)} MXN`}
							</Text>
						</Flex>
					</Flex>
				</Flex>
				<Flex
					w='100%'
					justify={{ base: 'center', md: 'space-around' }}
					direction={{ base: 'column', md: 'row' }}
				>
					<Flex
						direction='column'
						align='center'
						mb={{ base: '20px', md: '0px' }}
						transition='all 0.3s'
						_hover={{ transform: 'scale(1.05)' }}
						cursor='pointer'
						onClick={() => navigate('/client/wallet')}
					>
						<Flex align='center'>
							<Text
								color={textColor}
								fontSize={{ base: '24px', lg: '32px' }}
								fontWeight='700'
							>
								{currentCurrency === 'SATS' &&
									`${
										Intl.NumberFormat('en-US', {
											minimumFractionDigits: 0,
											maximumFractionDigits: 0,
										}).format(userBalanceAvailable) || '0'
									} SATS`}
								{currentCurrency === 'USD' &&
									`$${satoshiToUsd(userBalanceAvailable)} USD`}
								{currentCurrency === 'BTC' &&
									`${satoshiToBtc(userBalanceAvailable)} BTC`}
								{currentCurrency === 'MXN' &&
									`$${satoshiToMxn(userBalanceAvailable)} MXN`}
							</Text>
						</Flex>
						<Text color='secondaryGray.600' fontSize='sm' fontWeight='500'>
							Balance disponible
						</Text>
					</Flex>
					<VSeparator />
					<Flex
						direction='column'
						align='center'
						transition='all 0.3s'
						_hover={{ transform: 'scale(1.05)' }}
						cursor='pointer'
						onClick={() => navigate('/client/wallet')}
					>
						<Flex align='center'>
							<Text
								color={textColor}
								fontSize={{ base: '24px', lg: '32px' }}
								fontWeight='700'
							>
								{currentCurrency === 'SATS' &&
									`${
										Intl.NumberFormat('en-US', {
											minimumFractionDigits: 0,
											maximumFractionDigits: 0,
										}).format(userBalancePending) || '0'
									} SATS`}
								{currentCurrency === 'USD' &&
									`$${satoshiToUsd(userBalancePending)} USD`}
								{currentCurrency === 'BTC' &&
									`${satoshiToBtc(userBalancePending)} BTC`}
								{currentCurrency === 'MXN' &&
									`$${satoshiToMxn(userBalancePending)} MXN`}
							</Text>
						</Flex>
						<Text color='secondaryGray.600' fontSize='sm' fontWeight='500'>
							Balance pendiente
						</Text>
					</Flex>
				</Flex>
			</Card>
		</Flex>
	);
}
