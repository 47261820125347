import { Box, Flex, Text } from '@chakra-ui/react';

const NotificationCount = ({ textColor, notificationsCount }) => {
	return (
		<Flex alignItems='center'>
			<Text fontSize='md' fontWeight='600' color={textColor}>
				Notificaciones
			</Text>
			{notificationsCount > 0 && (
				<Box
					ml={2}
					bg='blue.500'
					color='white'
					borderRadius='50%'
					px={3}
					py={0.8}
					fontSize='0.7rem'
					fontWeight='bold'
					display='flex'
					alignItems='center'
					justifyContent='center'
				>
					{notificationsCount > 99 ? '99+' : notificationsCount}
				</Box>
			)}
		</Flex>
	);
};

export default NotificationCount;
