// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiDataGrid-menuList,
.MuiPopper-root.MuiDataGrid-menu,
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
	box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
	border-radius: 2rem;
}

.chakra-ui-dark .MuiDataGrid-menuList,
.chakra-ui-dark .MuiPaper-root.MuiPaper-elevation,
.chakra-ui-dark .MuiPopper-root.MuiDataGrid-panel {
	background-color: var(--chakra-colors-background-900);
	box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
	border-radius: 2rem;
}
.chakra-ui-dark .MuiSvgIcon-root,
.chakra-ui-dark .MuiButtonBase-root.MuiButton-root {
	color: #ffff;
}

.chakra-ui-dark .MuiDataGrid-overlayWrapper .MuiDataGrid-overlay {
	background-color: inherit;
}

.chakra-ui-dark .MuiDataGrid-columnsManagementFooter,
.chakra-ui-dark fieldset,
.chakra-ui-dark .MuiInput-underline:before,
.chakra-ui-dark .MuiDataGrid-toolbarQuickFilter .MuiInput-underline:before {
	border-color: #ffff;
}

.MuiDataGrid-topContainer::after {
	height: 0 !important;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-track {
	background-color: inherit;
}

.MuiDataGrid-scrollbar::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: 'inherit';
}

.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb {
	background-color: var(--chakra-colors-black);
	border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/MUI.css"],"names":[],"mappings":"AAAA;;;CAGC,wDAAwD;CACxD,mBAAmB;AACpB;;AAEA;;;CAGC,qDAAqD;CACrD,wDAAwD;CACxD,mBAAmB;AACpB;AACA;;CAEC,YAAY;AACb;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;;;;CAIC,mBAAmB;AACpB;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,2BAA2B;AAC5B;;AAEA;CACC,4CAA4C;CAC5C,mBAAmB;AACpB","sourcesContent":[".MuiDataGrid-menuList,\n.MuiPopper-root.MuiDataGrid-menu,\n.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {\n\tbox-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);\n\tborder-radius: 2rem;\n}\n\n.chakra-ui-dark .MuiDataGrid-menuList,\n.chakra-ui-dark .MuiPaper-root.MuiPaper-elevation,\n.chakra-ui-dark .MuiPopper-root.MuiDataGrid-panel {\n\tbackground-color: var(--chakra-colors-background-900);\n\tbox-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);\n\tborder-radius: 2rem;\n}\n.chakra-ui-dark .MuiSvgIcon-root,\n.chakra-ui-dark .MuiButtonBase-root.MuiButton-root {\n\tcolor: #ffff;\n}\n\n.chakra-ui-dark .MuiDataGrid-overlayWrapper .MuiDataGrid-overlay {\n\tbackground-color: inherit;\n}\n\n.chakra-ui-dark .MuiDataGrid-columnsManagementFooter,\n.chakra-ui-dark fieldset,\n.chakra-ui-dark .MuiInput-underline:before,\n.chakra-ui-dark .MuiDataGrid-toolbarQuickFilter .MuiInput-underline:before {\n\tborder-color: #ffff;\n}\n\n.MuiDataGrid-topContainer::after {\n\theight: 0 !important;\n}\n\n.MuiDataGrid-scrollbar::-webkit-scrollbar-track {\n\tbackground-color: inherit;\n}\n\n.MuiDataGrid-scrollbar::-webkit-scrollbar {\n\twidth: 10px;\n\theight: 10px;\n\tbackground-color: 'inherit';\n}\n\n.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb {\n\tbackground-color: var(--chakra-colors-black);\n\tborder-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
