// Chakra imports
import {
	Avatar,
	Box,
	Button,
	Flex,
	Icon,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { addLink } from 'api/link';
import ResultMessage from 'components/modal/ResultMessage';
import { ALERT } from 'constant/messages';
import { useState } from 'react';
import { IoMdLink } from 'react-icons/io';

// Assets
import { MdOutlineMonetizationOn } from 'react-icons/md';
import { RiShoppingCart2Line } from 'react-icons/ri';
import { useSelector } from 'react-redux';

export default function Summary(props) {
	const { brand, categoryAvatar, setLinkBuilder } = props;

	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const shadow = useColorModeValue(
		' 0px 50px 40px -34px rgba(112, 144, 176, 0.16)',
		'unset'
	);
	const borderColor = useColorModeValue('secondaryGray.400', 'transparent');
	const cardBg = useColorModeValue('white', 'navy.800');

	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState({
		state: false,
		title: '',
		subtitle: '',
	});

	const handleSubmit = async (affiliateLink, uniqueBumbeiId) => {
		try {
			setLoading(true);

			let linkObj = {
				link: affiliateLink,
				advertiserId: brand?.advertiserId,
				networkId: brand?.networkId,
				userId: USERID,
				brandId: brand?.id,
				domain: brand?.domain,
				bumbeiId: uniqueBumbeiId,
				status: 'activo',
				type: 'internal',
			};
			const response = await addLink(TOKEN, USERID, linkObj);
			const result = await response.json();

			if (!response.status === 200 || !result.status === 'SUCCESS') {
				handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
			}
		} catch (error) {
			console.error(error);
			handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
		} finally {
			setLoading(false);
		}
	};

	const handleBuyAction = () => {
		function generateUniqueId() {
			return Date.now();
		}

		let timeStamp = generateUniqueId();
		let concatenatedString = USERID + timeStamp.toString();
		let uniqueBumbeiId = Number(concatenatedString);
		if (brand.link) {
			let affiliateLink = brand.link
				.replace('{user}', uniqueBumbeiId)
				.replace('{encoded_url}', brand.domain);

			handleSubmit(affiliateLink, uniqueBumbeiId);
			window.open(affiliateLink, '_blank');
		} else {
			handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
		}
	};

	const handleLinkBuilderAction = () => {
		setLinkBuilder({
			state: true,
			title: 'Crea tu enlace personalizado',
			subtitle: `Deja un enlace de cualquier producto de ${brand.brandName} y lo convertiremos en un enlace de Bumbei para que generes cashback.`,
			brand: brand,
		});
	};

	const handleMessage = (state, title, subtitle) => {
		setMessage({ state: state, title: title, subtitle: subtitle });
	};

	const handleCloseModal = () => {
		setMessage({
			state: false,
			title: '',
			subtitle: '',
		});
	};

	return (
		<Flex
			direction='column'
			ps={{ base: 'unset', lg: "'65px'" }}
			mx='auto'
			maxW={{ base: '100%', md: 'max-content' }}
		>
			<Text
				color={textColor}
				fontSize={{ base: '36px', '2xl': '54px' }}
				fontWeight='700'
				mb='-30px'
				lineHeight='100%'
			>
				{brand.brandName}
			</Text>
			<Flex>
				<Flex
					mt='55px'
					mb='36px'
					me='auto'
					justifyContent='center'
					alignItems='center'
				>
					<Avatar
						h={{ base: '40px', md: '60px' }}
						w={{ base: '40px', md: '60px' }}
						src={categoryAvatar}
						me='20px'
					/>
					<Box>
						<Text color='secondaryGray.600' fontSize='md' fontWeight='500'>
							Categoría
						</Text>
						<Flex align='center'>
							<Text color={textColor} fontSize='lg' fontWeight='700' me='5px'>
								{brand?._categoryId?.name}
							</Text>
						</Flex>
					</Box>
				</Flex>
				<Flex mt='75px' mb='56px' justifyContent='center' alignItems='center'>
					<Flex
						h={{ base: '40px', md: '60px' }}
						w={{ base: '40px', md: '60px' }}
						bg='green.500'
						me='20px'
						borderRadius='50px'
						justify='center'
						align='center'
					>
						<Icon
							as={MdOutlineMonetizationOn}
							h={{ base: '25px', md: '38px' }}
							w={{ base: '25px', md: '38px' }}
							color='white'
						/>
					</Flex>
					<Box>
						<Text color='secondaryGray.600' fontSize='md' fontWeight='500'>
							Comisión
						</Text>
						<Flex align='center'>
							<Text color={textColor} fontSize='lg' me='6px' fontWeight='700'>
								{`${brand.rate}%`}
							</Text>
						</Flex>
					</Box>
				</Flex>
			</Flex>
			<Flex
				w='100%'
				align='center'
				p='40px'
				direction='column'
				border='1px solid'
				borderColor={borderColor}
				boxShadow={shadow}
				bg={cardBg}
				borderRadius='20px'
				mb='50px'
			>
				<Text fontWeight='500' color={textColor} fontSize='22px'>
					Tú eliges.
				</Text>
				<Text
					mt='10px'
					mb='30px'
					maxW='350px'
					color={textColor}
					fontSize='md'
					fontWeight='500'
					textAlign='center'
				>
					Compra y recibe reembolsos en Bitcoin a cambio, o genera tu propio
					enlace personalizado y compártelo con tu comunidad y amigos para
					ganar.
				</Text>

				<Flex
					flexDirection={{ sm: 'column', xl: 'row' }}
					gap={'1rem'}
					mb={{ base: '0px', md: '30px' }}
					w={'100%'}
				>
					<Button
						px='15px'
						variant='brand'
						fontSize='sm'
						fontWeight='500'
						h='46px'
						transition='all 0.3s'
						_hover={{ transform: 'scale(1.05)' }}
						onClick={handleBuyAction}
						w={{ sm: '100%' }}
						isLoading={loading}
					>
						Compra
						<Icon
							width='1rem'
							height='1rem'
							as={RiShoppingCart2Line}
							color='white'
							ml='0.5rem'
						/>
					</Button>
					<Button
						px='15px'
						variant='brand'
						fontSize='sm'
						fontWeight='500'
						h='46px'
						transition='all 0.3s'
						_hover={{ transform: 'scale(1.05)' }}
						onClick={handleLinkBuilderAction}
						w={{ sm: '100%' }}
					>
						Genera tu enlace
						<Icon
							width='1rem'
							height='1rem'
							as={IoMdLink}
							color='white'
							ml='0.5rem'
						/>
					</Button>
				</Flex>
			</Flex>
			<ResultMessage
				isOpen={message.state}
				onClose={handleCloseModal}
				message={message}
			/>
		</Flex>
	);
}
