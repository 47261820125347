import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { MdCheckCircleOutline, MdOutlineHighlightOff } from 'react-icons/md';
import { RiErrorWarningLine } from 'react-icons/ri';

export default function ResultMessage({ isOpen, onClose, message }) {
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent maxWidth={'400px'}>
				<ModalHeader
					display='flex'
					alignItems='center'
					gap='10px'
					pt='50px'
					textAlign='left'
				>
					{message.state === 'warning' && (
						<RiErrorWarningLine color={'#C27400'} size='25px' />
					)}
					{message.state === 'error' && (
						<MdOutlineHighlightOff color={'#E31A1A'} size='25px' />
					)}
					{message.state === 'success' && (
						<MdCheckCircleOutline color={'#5CB85C'} size='25px' />
					)}
					{message.title}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Text>{message.subtitle}</Text>
				</ModalBody>
				<ModalFooter gap='20px'>
					<Button onClick={onClose}>Cerrar</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
