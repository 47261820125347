import { LicenseInfo } from '@mui/x-license';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './assets/css/App.css';
import './assets/css/MUI.css';
import { persistor, store } from './store';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE_KEY);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>
);
