import {
	Alert,
	AlertDescription,
	AlertIcon,
	Box,
	Button,
	CircularProgress,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { forgotPassword } from 'api/user';
import { ERROR, SUCCESS } from 'constant/messages';

// Custom components
import CenteredAuth from 'layouts/auth/types/Centered';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

function ForgotPassword() {
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorSecondary = 'gray.400';
	const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
	const textColorBrand = useColorModeValue('brand.500', 'white');
	const brandStars = useColorModeValue('brand.500', 'brand.400');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [email, setEmail] = useState('');
	const [validEmail, setValidEmail] = useState(false);
	const [message, setMessage] = useState({ state: false, message: '' });
	const [loading, setLoading] = useState(false);
	const [sendAgain, setSendAgain] = useState(false);

	const handleFocus = () => {
		setMessage({
			state: false,
			status: '',
			description: '',
		});
		setIsSubmitted(false);
	};

	const validateEmail = (email) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			let isValidEmail = validateEmail(email);
			setValidEmail(isValidEmail);
			if (email && isValidEmail) {
				setLoading(true);
				const response = await forgotPassword(email);
				if (response.status === 200 || !response) {
					setMessage({
						state: true,
						status: 'success',
						description: sendAgain
							? SUCCESS.EMAIL_SENT_AGAIN
							: SUCCESS.EMAIL_SENT,
					});
					setTimeout(() => {
						setMessage({
							state: false,
							status: '',
							description: '',
						});
					}, 5000);
					setTimeout(() => {
						setSendAgain(true);
					}, 5000);
				} else {
					setMessage({
						state: true,
						status: 'error',
						description: ERROR.SOMETHING_WRONG,
					});
				}
			} else {
				setMessage({
					state: true,
					status: 'error',
					description: ERROR.VALID_EMAIL,
				});
			}
		} catch (error) {
			console.error(error);
			setMessage({
				state: true,
				status: 'error',
				description: ERROR.SOMETHING_WRONG,
			});
		} finally {
			setIsSubmitted(true);
			setLoading(false);
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			handleSubmit(e);
		}
	};

	const handleSendAgain = (e) => {
		handleSubmit(e);
	};

	return (
		<CenteredAuth
			image={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'}
			cardTop={{ base: '140px', md: '24vh' }}
			cardBottom={{ base: '50px', lg: 'auto' }}
		>
			<Flex
				w='100%'
				maxW='max-content'
				me='auto'
				h='100%'
				alignItems='start'
				justifyContent='center'
				px={{ base: '25px', md: '0px' }}
				flexDirection='column'
			>
				{loading && (
					<Flex w='100%' justifyContent={'center'}>
						<CircularProgress isIndeterminate color={textColor} />
					</Flex>
				)}
				{!loading && message.state && (
					<Alert status={message.status}>
						<AlertIcon />
						<AlertDescription>{message.description}</AlertDescription>
					</Alert>
				)}
				<Box mt='34px' me='auto' mb='44px'>
					<Heading
						color={textColor}
						fontSize={{ base: '3xl', md: '36px' }}
						mb='24px'
						textAlign='center'
					>
						¿Olvidaste tu contraseña?
					</Heading>
					<Text
						color={textColorSecondary}
						fontSize='md'
						w={{ base: '100%', lg: '456px' }}
						maxW='100%'
					>
						No hay problema. Solo déjanos tu dirección de correo electrónico y
						te enviaremos un enlace para restablecer tu contraseña que te
						permitirá elegir una nueva.
					</Text>
				</Box>
				<Flex
					zIndex='2'
					direction='column'
					w={{ base: '100%', lg: '456px' }}
					maxW='100%'
					background='transparent'
					borderRadius='15px'
					mx={{ base: 'auto', lg: 'unset' }}
					me='auto'
					mb={{ base: '20px', md: 'auto' }}
					align='start'
				>
					<FormControl>
						<FormLabel
							display='flex'
							ms='4px'
							fontSize='sm'
							fontWeight='500'
							color={textColor}
							mb='8px'
						>
							Correo electrónico<Text color={brandStars}>*</Text>
						</FormLabel>
						<Input
							id='recovery-email'
							variant='auth'
							fontSize='sm'
							type='email'
							placeholder='mail@bumbei.com'
							mb='24px'
							size='lg'
							value={email}
							onKeyDown={handleKeyDown}
							onChange={(e) => setEmail(e.target.value)}
							borderColor={isSubmitted && !validEmail ? 'red.500' : null}
							onFocus={handleFocus}
						/>
						{!sendAgain && (
							<Button
								fontSize='sm'
								variant='brand'
								fontWeight='500'
								w='100%'
								h='50'
								mb='24px'
								onClick={handleSubmit}
							>
								Enviar enlace de restablecimiento
							</Button>
						)}
					</FormControl>
					{sendAgain && (
						<Flex
							flexDirection='row'
							justifyContent='space-between'
							alignItems='start'
							w='100%'
							mt='0px'
						>
							<Text
								color={textColorDetails}
								fontWeight='400'
								fontSize='14px'
								mx={{ base: 'auto', lg: 'unset' }}
								textAlign={{ base: 'center', lg: 'left' }}
							>
								¿No te ha llegado?
								<Text
									textDecoration='underline'
									color={textColorBrand}
									as='span'
									ms='5px'
									fontWeight='500'
									onClick={handleSendAgain}
									_hover={{ cursor: 'pointer' }}
								>
									Reenviar código
								</Text>
							</Text>
							<NavLink to='/auth/sign-in'>
								<Text
									textDecoration='underline'
									color={textColorBrand}
									fontSize='sm'
									fontWeight='500'
								>
									Volver a inicio
								</Text>
							</NavLink>
						</Flex>
					)}
				</Flex>
			</Flex>
		</CenteredAuth>
	);
}

export default ForgotPassword;
